import React from 'react';
import { matchPath, useLocation, Link } from 'react-router-dom';

import { Home, User, Cart, Bell, Order } from 'components/icons/bottomNav';
import clsx from 'clsx';

const OldSchoolMenuLink = ({
  label,
  to,
  text,
  activeOnlyWhenExact = false,
}) => {
  // let match = useRouteMatch({
  //   path: to,
  //   exact: activeOnlyWhenExact,
  // });

  const { pathname } = useLocation();
  const matched = matchPath(to, pathname);

  const color = matched ? '#1965e3' : '#999';

  let icon = null;
  switch (label) {
    case 'Home':
      icon = <Home width={20} height={20} fill={color} />;
      break;
    case 'Bills':
      icon = <Bell width={20} height={20} fill={color} />;
      break;
    case 'Orders':
      icon = <Order width={20} height={20} fill={color} />;
      break;
    case 'My':
      icon = <User width={20} height={20} fill={color} />;
      break;
  }
  // {icon}

  return (
    <Link
      to={to}
      className='inline-flex flex-col items-center justify-center px-5'
    >
      {icon}
      <span
        className={clsx('text-sm mt-1', {
          active: matched,
        })}
      >
        {text}
      </span>
    </Link>
  );
};

export const BottomNav: React.FC = () => {
  return (
    <nav className='left-0 z-30 h-16 bg-white border-t border-gray-200 absolute bottom-0 w-full'>
      <div className='grid h-full max-w-lg grid-cols-4 mx-auto font-medium'>
        <OldSchoolMenuLink
          to='/'
          label='Home'
          text='首页'
          activeOnlyWhenExact={true}
        />
        <OldSchoolMenuLink to='/bills' label='Bills' text='帳單' />
        <OldSchoolMenuLink to='/orders' label='Orders' text='訂單' />
        <OldSchoolMenuLink to='/my' label='My' text='我的' />
      </div>
    </nav>
  );
};
