import React from 'react';

export const Loading = ({ isLoading = false }: { isLoading: boolean }) => {
  if (!isLoading) return null;

  return (
    <div className='absolute left-0 right-0 top-0 bottom-0 flex align-middle justify-center z-50'>
      <div className='inline-flex items-center bg-black opacity-80 self-center px-4 py-4 font-semibold leading-6 text-sm shadow rounded-md text-white cursor-not-allowed'>
        <svg
          className='animate-spin h-10 w-10 text-white'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          width='10' // 增加宽度
          height='10' // 增加高度
        >
          <circle
            className='opacity-25'
            cx='12'
            cy='12'
            r='10'
            stroke='currentColor'
            strokeWidth='4'
          ></circle>
          <path
            className='opacity-75'
            fill='currentColor'
            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
          ></path>
        </svg>
      </div>
    </div>
  );
};
