import { createSlice } from '@reduxjs/toolkit';

interface ICart {
  cartOrder: any[];
}

const initialState: ICart = {
  cartOrder: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, { payload }) => {
      state.cartOrder = payload;
    },
  },
});

export const { addToCart } = cartSlice.actions;

export default cartSlice.reducer;
