import { Container } from 'components';
import { BottomNav } from 'components/bottomNav';
import { useDispatch } from 'react-redux';
import { setCurrentRouteConfig } from 'redux/actions';

const RouteLayout = ({
  children,
  path = '',
  showHeader = true,
  showBottomNav = true,
  allowGoBack = true,
}) => {
  const dispatch = useDispatch();

  dispatch(
    setCurrentRouteConfig({
      path,
      showBottomNav,
      showHeader,
      allowGoBack,
    })
  );

  return (
    <Container hideHeader={!showHeader} showBottomNav={showBottomNav}>
      {children}
      {showBottomNav ? <BottomNav /> : null}
    </Container>
  );
};

export default RouteLayout;
