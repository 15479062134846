import * as React from "react";
function SvgMenuOrder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M7.239 18.405c.229-2.194 2.215-2.61 3.86-3.732 1.34-.915 2.099-2.676 5.106-3.248 3.007-.57 6.185 1.44 6.23 4.666.045 3.225-2.914 5.935-7.688 5.273-4.774-.662-7.738-.764-7.508-2.959"
          fill="#46CECF"
          opacity={0.596}
        />
        <path
          d="M5 20h14V4H5v16zM19.364 2H4.636C3.733 2 3 2.716 3 3.6v16.8c0 .884.733 1.6 1.636 1.6h14.728c.904 0 1.636-.716 1.636-1.6V3.6c0-.884-.732-1.6-1.636-1.6z"
          fill="#333"
        />
        <path
          d="M15.27 8c.401-.003.728.443.73.996.002.526-.294.964-.675 1.001L15.27 10H8.73c-.401.003-.728-.443-.73-.996-.002-.526.294-.964.675-1.001L8.73 8h6.54z"
          fill="#333"
        />
        <path
          d="M16 12v1c0 .552-.326 1-.727 1H8.727C8.326 14 8 13.552 8 13v-1h8z"
          fill="#5488F8"
        />
      </g>
    </svg>
  );
}
export default SvgMenuOrder;
