import React from 'react';

import { Container, Header } from 'components';
import { CategoryCard } from 'components/categoryCard/indetx';
import { useGetCategoriesQuery } from 'redux/services/common';
import { Link } from 'react-router-dom';
import { LineHeader } from 'components/header/lineHeader';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import clsx from 'clsx';

const Home: React.FC = () => {
  const { data, isLoading } = useGetCategoriesQuery();
  const currentRouteConfig = useSelector(
    (state: RootState) => state.rootReducer.route.currentRouteConfig
  );

  return (
    <>
      <LineHeader title='訂購分類' subtitle='分類一覽' />

      <div
        className={clsx('list-wrapper', {
          'pb-4': !currentRouteConfig?.showBottomNav,
        })}
      >
        <div className='grid grid-cols-2 gap-4'>
          {data?.map((item) => {
            return (
              <Link
                className='border rounded shadow-md bg-white'
                to={`/product/${item?.id}`}
                state={{ typeId: item?.id, typeName: item?.name }}
              >
                <img
                  className='w-auto'
                  src='https://placehold.co/300x300'
                  alt='Google HQ'
                />
                <div className='p-2'>
                  <h1 className='text-lg font-medium tracking-widest '>
                    {item?.name}
                  </h1>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Home;
