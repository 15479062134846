import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDeleteOrderMutation } from 'redux/services/common';
import { Container, Header } from 'components';
import dayjs from 'dayjs';
import { NavBack } from 'components/icons';
import { OrderStatusMap } from 'definitions/order';

const Detail: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  // const products = JSON.parse(state?.detail) ?? [];

  const topRef = useRef(null);
  const listRef = useRef(null);

  useEffect(() => {
    const topHeight = topRef.current.offsetHeight - 40;
    const remainingHeight = window.innerHeight - topHeight;

    topRef.current.style.cssText = `max-height:${topHeight}px; height: ${topHeight}px`;
    listRef.current.style.cssText = `max-height:${remainingHeight}px; height: ${remainingHeight}px;`;
  }, []);

  const orders = state?.detail ?? [];

  return (
    <>
      <div className='flex flex-col  bg-[#f7f7f7] overflow-hidden' ref={topRef}>
        <div
          className={
            'absolute left-0 right-0 top-0 flex justify-center h-[50px] max-width  transition-colors duration-200 z-10'
          }
        >
          <div className='absolute top-0 bottom-0 flex pl-2 left-0'>
            {/* <button
              className={'font-bold'}
              onClick={(e) => {
                e.stopPropagation();
                navigate(-1);
              }}
            >
              <NavBack fontSize={24} color={'white'} />
            </button> */}
          </div>
          <div className='flex basis-auto items-center'>
            <span className='font-[600] text-[18px] font-mono'>帳單明細</span>
          </div>
          <div className='absolute top-0 bottom-0 flex pr-2 right-0'>
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigate(-1);
              }}
            ></button>
          </div>
        </div>

        <div className='relative rounded-b-3xl product-confirm-bg flex flex-col  justify-center'>
          <div className='flex flex-1 flex-col justify-center items-center h-full pt-[50px] pb-[60px]'>
            {/* sum */}
            <div className='flex flex-col items-center'>
              <span
                className='text-lg font-bold'
                style={{ fontFamily: 'sans-serif' }}
              >
                總計
              </span>
              <span className='text-white text-3xl'>$341,134</span>
            </div>
          </div>
        </div>
        {/* period */}
        <div
          className='flex flex-1 border rounded-lg br-4 bg-white mb-2 shadow w-[80%] m-auto top-[-50px] relative'
          onClick={() => {}}
        >
          <div className='flex flex-1 py-4 px-2'>
            <div className='flex-row base-auto'>
              <div className='flex flex-col'>
                <span className='text-[#999] text-sm text-left font-medium'>
                  起始日
                </span>
                <span className='text-[#333] font-bold text-xl'>
                  {dayjs(state?.start).format('MM / DD')}
                </span>
              </div>
            </div>
            <div className='flex flex-1 justify-center items-center relative'>
              <div className='absolute w-10/12 border-t border-dashed border-[#ccc]' />
              <div className='absolute py-px px-4 border rounded-full h-fit bg-white'>
                <span className='text-base text-[#ed5655] font-bold'>
                  {state?.detail?.length}
                </span>
                <span className='text-sm text-[#ed5655] ml-1'>筆訂單</span>
              </div>
            </div>
            <div className='flex-row base-auto'>
              <div className='flex flex-col '>
                <span className='text-[#999] text-sm text-right font-medium'>
                  截止日
                </span>
                <span className='text-[#333] font-bold text-xl'>
                  {dayjs(state?.end).format('MM / DD')}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/** End period */}
      </div>
      <div
        className='flex flex-col bg-transparent p-4 overflow-y-scroll hide-scrollbar'
        ref={listRef}
      >
        {orders?.map((item, index) => {
          const mapData = OrderStatusMap.get(item?.status);

          return (
            <div
              key={index}
              className='p-2 border rounded-lg br-4 bg-white mb-2 shadow'
              onClick={() => {
                navigate(`/order/${item?.id}`, {
                  state: item,
                });
              }}
            >
              <div className='flex flex-row mb-2'>
                <div className='flex flex-1 flex-col leading-none'>
                  <h1
                    className='text-lg font-semibold '
                    style={{ fontFamily: 'sans-serif' }}
                  >
                    {`#${index + 1} ${item?.order__type?.name ?? ''}`}
                  </h1>
                </div>
                <div className='flex  basis-auto items-center justify-center'>
                  <div
                    className='px-4 py-1 rounded-xl shadow'
                    style={{ backgroundColor: mapData?.backgroundColor }}
                  >
                    <span
                      className='flex text-xs font-semibold'
                      style={{ color: mapData?.textColor }}
                    >
                      {mapData?.text}
                    </span>
                  </div>
                </div>
              </div>
              <div className='flex flex-row'>
                <div className='flex flex-1 justify-center flex-col'>
                  <span className='text-sm'>{`${
                    item?.detail?.length ?? 0
                  } 項商品`}</span>
                  <span className='text-xs text-gray-400'>
                    {dayjs(item?.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                  </span>
                </div>
                <div className='flex basis-auto items-center justify-center'>
                  <span className='text-2xl font-semibold'>{`$ ${(
                    (item?.amount * 1000) /
                    1000
                  ).toLocaleString()}`}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Detail;
