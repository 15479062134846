import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import clsx from 'clsx';
import { ShowPwd, HidePwd } from 'components/icons';
interface LabelInputProps {
  label?: string;
  labelStyle?: React.CSSProperties;
  placeholder?: string;
  containerClass?: string;
}

type inputHandler = {
  getValue: () => string;
};

const LabelInput = forwardRef<inputHandler, LabelInputProps>(
  (
    { label = '', labelStyle = null, containerClass = null, placeholder = '' },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const inputType = showPassword ? 'text' : 'password';

    const [inputStr, setInputStr] = useState('');
    useImperativeHandle(ref, () => ({
      getValue: () => inputStr,
    }));

    return (
      <div className={clsx('flex flex-col', containerClass)}>
        {label ? (
          <label className='block mb-1 ml-2 text-sm font-medium text-bc-666'>
            {label}
          </label>
        ) : null}
        <div className='flex flex-row border bg-white rounded-lg focus-within:border-blue-500'>
          <input
            type={inputType}
            className=' text-bc-666 text-sm rounded-lg block w-full p-2.5 outline-none'
            placeholder={placeholder}
            required
            maxLength={16}
            onChange={(e) => {
              setInputStr(e.target.value);
            }}
          />
          <div className='base-auto items-center justify-center p-2 '>
            <ShowPwd
              fontSize={24}
              onClick={() => {
                setShowPassword((prev) => !prev);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default LabelInput;
