import { Button } from 'components';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Loading } from 'components';
import Toast from 'components/toast';
import { useUser } from 'hooks';
import { useSelector } from 'react-redux';
import { addToCart } from 'redux/actions';
import { RootState } from 'redux/reducers';
import { useAppDispatch } from 'redux/store';

import { commonApi, useSubmitOrderMutation } from 'redux/services/common';

const ProductConfirmOrder = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const listRef = useRef(null);
  const productConfirmBgRef = useRef(null);
  const buttonsRef = useRef(null);

  const { userInfo, userConstant } = useUser();

  const [note, setNote] = useState('');
  const [total, setTotal] = useState(0);
  const [submitOrder, { data, isSuccess, error, isLoading }] =
    useSubmitOrderMutation();

  const [listMaxHeight, setListMaxHeight] = useState(0);
  const orderItems = useSelector(
    (state: RootState) => state.rootReducer.cart.cartOrder
  );

  useEffect(() => {
    let totalAmount = 0;

    orderItems?.forEach((product, index) => {
      const modifyNum = +product?.number - +product?.mod_number;
      const itemAmount =
        product?.price?.[userConstant.roleKey]?.[userConstant?.regionKey] *
        product?.number;
      totalAmount += itemAmount;
    });

    setTotal(totalAmount);
  }, [orderItems?.length]);

  useEffect(() => {
    const productConfirmBgHeight = productConfirmBgRef.current.offsetHeight;
    const buttonsHeight = buttonsRef.current.offsetHeight;
    const remainingHeight =
      window.innerHeight - productConfirmBgHeight - buttonsHeight - 40;
    // listRef.current.style.maxHeight = `${remainingHeight}px`;
    // listRef.current.style.Height = `${remainingHeight}px`;
    // setListMaxHeight(Math.round(remainingHeight));
    listRef.current.style.cssText = `max-height:${remainingHeight}px; height: ${remainingHeight}px;`;
    // listInnerRef.current.style.maxHeight = `${remainingHeight - 40}px`;
  }, []);

  return (
    <div className='flex flex-col  bg-[#f7f7f7] overflow-x-hidden'>
      <div
        className='relative rounded-b-3xl product-confirm-bg flex  justify-center'
        ref={productConfirmBgRef}
      >
        <div
          // style={{ height: '200px' }}
          className='p-4 flex flex-col items-center justify-center pt-[50px] pb-[50px]'
        >
          <span
            className='mb-4'
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              color: '#fff',
            }}
          >
            確認購物車內的商品與金額
          </span>
          <div>
            <span className='text-4xl font-bold'>{`$${total?.toLocaleString()}`}</span>
          </div>
        </div>
      </div>
      <div className='flex flex-row justify-between p-4 py-2'>
        <span>{`共 ${orderItems?.length} 項商品`}</span>
      </div>
      <div
        className='flex-col modal-product-list p-4 py-2 hide-scrollbar'
        id='list'
        ref={listRef}
      >
        <div className='divide-y border-solid border border-grey-100 rounded-2xl bg-white '>
          {orderItems?.map((product, index) => {
            const modifyNum = +product?.number - +product?.mod_number;

            const numberStyle = product?.mod_number ? 'line-through mr-2' : '';

            const itemAmount =
              product?.price?.[userConstant.roleKey]?.[
                userConstant?.regionKey
              ] * product?.number;

            return (
              <div
                key={`item-${index}`}
                className='flex flex-row justify-between items-center p-3 '
              >
                <div className='flex  flex-row items-center'>
                  <div className='flex rounded-full w-12 h-12 bg-gray-200 basis-auto mr-2' />
                  <div className='flex-col  basis-auto'>
                    <span className='flex text-lg'>{product?.name ?? ''}</span>
                    <span className='flex text-gray-400 text-lg'>
                      {`$${
                        product?.price?.[userConstant.roleKey]?.[
                          userConstant?.regionKey
                        ]
                      }`}
                    </span>
                  </div>
                </div>

                <div className='flex flex-col basis-auto items-end'>
                  <span className='flex text-lg'>{`x${product?.number}`}</span>
                  <span className='flex text-green-600 text-lg'>{`$${itemAmount?.toLocaleString()}`}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div
        id='buttons'
        ref={buttonsRef}
        className='flex flex-col p-[20px]'
        style={{
          flexBasis: 'auto',
        }}
      >
        <div className='p-4 bg-white rounded mb-4'>
          <textarea
            className='resize-none w-full text-sm text-333 outline-none'
            placeholder={'備註訊息'}
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            maxLength={200}
            rows={4}
          ></textarea>
        </div>
        <div className='flex flex-row'>
          <Button
            className='rounded-3xl py-2 w-full font-bold text-yellow-500 bg-white border mr-2'
            style={{ borderColor: '#2196F3', color: '#2196F3' }}
            onClick={() => navigate(-1)}
          >
            取消
          </Button>
          <Button
            className='rounded-3xl py-2 w-full font-bold text-white tracking-wide'
            style={{ backgroundColor: '#2196F3' }}
            isLoading={false}
            onClick={() => {
              const tmp = [];
              let typeId = 0,
                orderSum = 0;

              orderItems?.map((item) => {
                typeId = item?.typeId;

                if (item?.buy_count != 0 || item?.mod_count != 0) {
                  const amount =
                    item?.price?.[userConstant.roleKey]?.[
                      userConstant?.regionKey
                    ] * item?.number;

                  tmp.push({
                    id: item.id,
                    name: item.name,
                    thumbnail: item.thumbnail,
                    price:
                      item?.price?.[userConstant.roleKey]?.[
                        userConstant?.regionKey
                      ],
                    number: +item.number,
                    mod_number: parseInt(item.mod_count) || null,
                    amount,
                  });

                  orderSum += amount;
                }
              });

              const orderInfo = {
                order: tmp,
                sum: orderSum,
                typeId,
                note,
              };

              submitOrder(orderInfo)
                .unwrap()
                .then((res) => {
                  dispatch(commonApi.util.resetApiState());

                  Toast.info('訂單新增成功，即將跳轉。', 2000, () => {
                    navigate('/', { replace: true });
                    dispatch(addToCart([]));
                  });
                })
                .catch((err) => {
                  Toast.info(err?.data?.message, 5000, () => {
                    navigate('/', { replace: true });
                    dispatch(addToCart([]));
                  });
                });
            }}
          >
            確認送出
          </Button>
        </div>
      </div>
      <Loading isLoading={isLoading} />
    </div>
  );
};

export default ProductConfirmOrder;
