import * as React from 'react';
function SvgNavBack(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color ?? '#333';

  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.788 11.995l9.058-8.058c.44-.387.507-1.107.107-1.56a1.114 1.114 0 00-1.56-.107L6.386 11.168c-.28.226-.387.507-.387.827 0 .333.107.613.387.827l10.005 8.898c.227.16.507.28.72.28.334 0 .614-.107.828-.387.386-.44.386-1.16-.107-1.56l-9.045-8.058z'
        fill={color}
        fillRule='evenodd'
      />
    </svg>
  );
}
export default SvgNavBack;
