import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IRoute {
  currentRouteConfig: any;
}

interface RouteConfig {
  path: string;
  showHeader: boolean;
  showBottomNav: boolean;
  allowGoBack: boolean
}

const initialState: IRoute = {
  currentRouteConfig: {},
};

const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setCurrentRouteConfig: (state, action: PayloadAction<RouteConfig>) => {
      state.currentRouteConfig = action.payload;
    },
  },
});

export const { setCurrentRouteConfig } = routeSlice.actions;

export default routeSlice.reducer;
