import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Loading } from 'components';

const ImageModal: React.FC = () => {
  const {
    state,
    state: { image },
  } = useLocation();

  const navigate = useNavigate();

  return (
    <div className='absolute inset-0 flex justify-center'>
      <div
        onClick={() => {
          navigate(-1);
        }}
        className='absolute inset-0'
        style={{
          background: 'rgba(0, 0, 0, 0.45)',
        }}
      ></div>
      <div className='container items-center justify-center p-4'>
        <img src={image} className='object-contain z-10' />
      </div>
    </div>
  );
};

export default ImageModal;
