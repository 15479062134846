import * as React from "react";
function SvgBell(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M23.259 16.2l-2.6-9.371A9.321 9.321 0 002.576 7.3L.565 16.35A3 3 0 003.493 20H7.1a5 5 0 009.8 0h3.47a3 3 0 002.89-3.8zM12 22a3 3 0 01-2.816-2h5.632A3 3 0 0112 22zm9.165-4.395a.993.993 0 01-.8.395H3.493a1 1 0 01-.976-1.217l2.011-9.05a7.321 7.321 0 0114.2-.372l2.6 9.371a.993.993 0 01-.163.873z"
        data-name="01 align center"
      />
    </svg>
  );
}
export default SvgBell;
