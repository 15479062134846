import * as React from "react";
function SvgMenuMember(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M7.239 19.405c.229-2.194 2.215-2.61 3.86-3.732 1.34-.915 2.099-2.676 5.106-3.248 3.007-.57 6.185 1.44 6.23 4.666.045 3.225-2.914 5.935-7.688 5.273-4.774-.662-7.738-.764-7.508-2.959"
          fill="#46CECF"
          opacity={0.599}
        />
        <path
          d="M4 18h16V7H4v11zM20.461 5H3.538C2.688 5 2 5.672 2 6.5v12c0 .828.689 1.5 1.538 1.5h16.923c.85 0 1.539-.672 1.539-1.5v-12c0-.828-.689-1.5-1.539-1.5z"
          fill="#444"
        />
        <path
          d="M14.64 9.038c1.528 0 2.569.61 3.082 1.649.34.686.405 1.464.31 2.138a3.854 3.854 0 01-.706 1.754l-.02.026 1.941 1.637c.234.196.376.476.397.775l.003.075v1.586a.752.752 0 01-.76.745.755.755 0 01-.764-.69l-.002-.055v-1.423l-2.422-2.041a.727.727 0 01-.006-1.115l.048-.038c.409-.299.698-.858.78-1.437.057-.411.015-.912-.175-1.296-.254-.514-.754-.807-1.706-.807-.932 0-1.408.3-1.652.838-.178.392-.212.86-.167 1.29.045.425.341 1.039.692 1.345l.062.05a.728.728 0 01.08 1.104l-.045.042-2.437 2.064v1.424a.75.75 0 01-.706.74l-.057.002c-.4 0-.731-.3-.761-.686l-.002-.056v-1.587c0-.3.124-.586.341-.797l.057-.051 1.972-1.672-.007-.01a3.909 3.909 0 01-.695-1.66l-.012-.1c-.07-.662-.019-1.363.288-2.04.488-1.078 1.515-1.723 3.049-1.723z"
          fill="#444"
        />
        <path
          d="M10 9v1c0 .552-.32 1-.714 1H5.714C5.32 11 5 10.552 5 10V9h5z"
          fill="#5488F8"
        />
      </g>
    </svg>
  );
}
export default SvgMenuMember;
