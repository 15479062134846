import React, { useRef } from 'react';

import { Container } from 'components';
import { MenuBill, MenuOrder, MenuMember, Next } from 'components/icons';
import { Link, useNavigate } from 'react-router-dom';
import LabelInput from 'components/labelInput';
import { Button, Header } from 'components';
import { useEditPwdMutation } from 'redux/services/common';
import toast from 'react-hot-toast';
import Toast from 'components/toast';

const EditPassword = () => {
  const navigate = useNavigate();

  const oldPwdRef = useRef(null);
  const newPwdRef = useRef(null);
  const confirmPwdRef = useRef(null);

  const [editPwd, { data: result, isSuccess, error, isLoading }] =
    useEditPwdMutation();

  const submit = () => {
    if (
      oldPwdRef.current.getValue() === '' ||
      newPwdRef.current.getValue() === '' ||
      confirmPwdRef.current.getValue() === ''
    ) {
      return Toast.info('必填欄位無法為空', 1500);
    }

    const payload = {
      current_password: oldPwdRef.current.getValue(),
      password: newPwdRef.current.getValue(),
      password_confirmation: confirmPwdRef.current.getValue(),
    };

    editPwd(payload)
      .unwrap()
      .then((res) => {
        Toast.info('密碼已重新設定', 1500, () => {
          navigate(-1);
        });
      })
      .catch((err) => {
        Toast.info('重設密碼異常，請稍後再試', 1500);
      });
  };

  return (
    <div className='bg-bc-lightgrey h-full'>
      <Header title='修改登入密碼' />
      <div className='flex flex-1 flex-col bg-bc-lightgrey p-3.5'>
        <div className='flex flex-col'>
          <LabelInput containerClass='mb-4' label='舊密碼' ref={oldPwdRef} />
          <LabelInput containerClass='mb-4' label='新的密码' ref={newPwdRef} />
          <LabelInput
            containerClass='mb-4'
            label='请再次确认新的密码'
            ref={confirmPwdRef}
          />
        </div>
        <div className='mt-8'>
          <Button
            className='w-full items-center rounded-lg drop-shadow-lg'
            onClick={submit}
            isLoading={isLoading}
          >
            <span>確定</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditPassword;
