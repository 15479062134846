import * as React from "react";
function SvgMenuBill(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M7.851 19.6c.23-2.194 2.215-2.61 3.86-3.732 1.34-.915 2.1-2.676 5.106-3.248 3.008-.57 6.186 1.44 6.23 4.666.046 3.225-2.914 5.935-7.688 5.273-4.773-.662-7.737-.764-7.508-2.959"
          fill="#46CECF"
          opacity={0.596}
        />
        <path
          d="M4 20h16V4H4v16zM20.4 2H3.6A1.6 1.6 0 002 3.6v16.8A1.6 1.6 0 003.6 22h16.8a1.6 1.6 0 001.6-1.6V3.6A1.6 1.6 0 0020.4 2z"
          fill="#444"
        />
        <g fill="#5488F8">
          <path d="M15.299 13.815h-2.6v-1.358h2.6l.052-.002a.706.706 0 00.644-.708.705.705 0 00-.696-.713h-1.844l2.235-2.882.033-.047a.72.72 0 00-.148-.952.69.69 0 00-.981.118l-2.793 3.601-.033.047a.723.723 0 00-.062.115H8.701l-.052.002a.706.706 0 00-.649.713c.002.392.316.71.701.708h2.6v1.358h-2.6l-.052.002a.706.706 0 00-.644.708.704.704 0 00.696.712h2.6v1.72l.003.053c.028.37.33.656.694.657a.705.705 0 00.7-.71v-1.72h2.6l.053-.001a.706.706 0 00.649-.713.705.705 0 00-.701-.708" />
          <path d="M8.15 8.246l1.642 2.31h1.764l-2.32-3.264c-.238-.333-.673-.39-.973-.126a.828.828 0 00-.147 1.029l.033.05z" />
        </g>
      </g>
    </svg>
  );
}
export default SvgMenuBill;
