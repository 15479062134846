import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { BillStatus, BillStatusMap } from 'definitions/bill';
import { useGetBillsQuery, orderType } from 'redux/services/common';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import dayjs from 'dayjs';
import { NoData } from 'components';
interface BillListProps {
  status: BillStatus;
}

const BillList = ({ status }: BillListProps) => {
  const navigate = useNavigate();
  const [qureyParams, setQueryParams] = useState({
    _start: 0,
    _end: 10,
    pageSize: 10,
    status,
  });

  const [listData, setListData] = useState<any[]>([]);

  const { data: bills, isLoading, isSuccess } = useGetBillsQuery(qureyParams);

  const scrollRef = useBottomScrollListener(() => {
    if (bills?.pagination?.total > listData?.length)
      setQueryParams((prev) => ({
        ...prev,
        _start: prev._end,
        _end: prev._end + prev.pageSize,
        status,
      }));
  });

  useEffect(() => {
    if (isSuccess) {
      if (listData?.length) {
        setListData((prev) => [...prev, ...bills?.data]);
      } else {
        setListData(bills?.data);
      }
    }
  }, [bills]);

  return (
    <>
      {listData?.length ? (
        <div
          className='list-wrapper'
          ref={scrollRef as React.RefObject<HTMLDivElement>}
          style={{ backgroundColor: '#f7f7f7' }}
        >
          <div className='flex-1'>
            {listData?.map((item, index) => {
              const mapData = BillStatusMap.get(item?.status);

              return (
                <div
                  key={index}
                  className='flex flex-1 border rounded-lg br-4 bg-white mb-2 shadow'
                  onClick={() => {
                    navigate(`/bill/${item?.id}/detail`, {
                      state: item,
                    });
                  }}
                >
                  <div className='flex flex-1 py-4 px-2'>
                    <div className='flex-row base-auto'>
                      <div className='flex flex-col'>
                        <span className='text-[#999] text-sm text-left font-medium'>
                          起始日
                        </span>
                        <span className='text-[#333] font-bold text-xl'>
                          {dayjs(item?.start).format('MM / DD')}
                        </span>
                      </div>
                    </div>
                    <div className='flex flex-1 justify-center items-center relative'>
                      <div className='absolute w-10/12 border-t border-dashed border-[#ccc]' />
                      <div className='absolute py-px px-2 border rounded-full h-fit bg-white'>
                        <span className='text-xs text-[#ed5655]'>
                          {`${item?.detail?.length} 筆訂單`}
                        </span>
                      </div>
                    </div>
                    <div className='flex-row base-auto'>
                      <div className='flex flex-col '>
                        <span className='text-[#999] text-sm text-right font-medium'>
                          截止日
                        </span>
                        <span className='text-[#333] font-bold text-xl'>
                          {dayjs(item?.end).format('MM / DD')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='flex basis-[28%] relative border-l-2 py-2 pr-2 items-end justify-end border-dashed border-[#e9e9e9] '>
                    <div
                      className='absolute px-4 py-1 rounded-s-xl right-0 top-3 shadow'
                      style={{ background: mapData.backgroundColor }}
                    >
                      <span
                        className='flex text-xs font-semibold'
                        style={{ color: mapData.textColor }}
                      >
                        {mapData?.text}
                      </span>
                    </div>

                    <span className='text-xl font-semibold mb-1'>{`$ ${Math.ceil(
                      item?.amount ?? 0
                    ).toLocaleString()}`}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className='flex h-full items-center'>
          <NoData />
        </div>
      )}
    </>
  );
};

export default BillList;
