import React from 'react';

import { Container } from 'components';
import { MenuBill, MenuOrder, MenuMember, Next } from 'components/icons';
import { Link } from 'react-router-dom';
import { Button, Header } from 'components';

const Center: React.FC = () => {
  const ThumbnailSection = () => {
    return (
      <div className='flex flex-col  items-center justify-center py-8'>
        <div className='w-20 h-20 rounded-full bg-slate-300 shadow'></div>
      </div>
    );
  };

  return (
    <div className='bg-bc-lightgrey h-full'>
      <Header title='會員中心' />
      <div className='flex flex-1 flex-col h-full bg-bc-lightgrey'>
        <ThumbnailSection />
        <div className='grid grid-cols-1 gap-y-3.5 px-3.5'>
          <div className='bg-white p-3.5 rounded-lg shadow-sm justify-between flex-row flex items-center'>
            <span className='text-bc-333'>用戶名</span>
            <span className='text-bc-999'>測試一號</span>
          </div>
          <div className='bg-white p-3.5 rounded-lg shadow-sm justify-between flex-row flex items-center'>
            <span className='text-bc-333'>分店名</span>
            <span className='text-bc-999'>測試一號</span>
          </div>
          <div className='bg-white p-3.5 rounded-lg shadow-sm justify-between flex-row flex items-center'>
            <span className='text-bc-333'>建立日期</span>
            <span className='text-bc-999'>2018 / 12 / 05</span>
          </div>

          <Link
            to={'/my/editPassword'}
            className='bg-white p-3.5 rounded-lg shadow-sm justify-between flex-row flex items-center'
          >
            <span className='text-bc-333'>登入密碼變更</span>
            <Next />
          </Link>
          <div className='bg-white p-3.5 rounded-lg shadow-sm items-center justify-center flex mt-8'>
            <span>登出</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Center;
