import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { commonApi, useEditOrderMutation } from 'redux/services/common';
import { Loading } from 'components';
import Toast from 'components/toast';
import { useAppDispatch } from 'redux/store';

const EditOrderConfirm: React.FC = () => {
  const {
    state,
    state: { editProducts, amount, orderTypeId, prevAmount, id },
  } = useLocation();
  const modalRef = useRef();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const changedAmount = useRef(0);

  const [editOrder, { data, isSuccess, isLoading, error }] =
    useEditOrderMutation();

  // useEffect(() => {
  //   const observerRefValue = modalRef.current;
  //   disableBodyScroll(observerRefValue);
  //   return () => {
  //     if (observerRefValue) {
  //       enableBodyScroll(observerRefValue);
  //     }
  //   };
  // }, []);

  const back = (e) => {
    e.stopPropagation();
    navigate(-1);
  };

  const save = () => {
    navigate(-1);

    editOrder({
      order: editProducts,
      sum: changedAmount.current,
      orderTypeId,
      id: +id,
      note: 'AAAAA',
    })
      .unwrap()
      .then((res) => {
        dispatch(commonApi.util.resetApiState());

        Toast.info('訂單修改成功，即將跳轉。', 2000, () => {
          navigate('/orders', { replace: true });
        });
      })
      .catch((err) => {
        Toast.info(err?.data?.error, 2000, () => {
          navigate('/orders', { replace: true });
        });
      });
  };

  return (
    <>
      <div className='absolute inset-0 flex justify-center' ref={modalRef}>
        <div
          onClick={back}
          className='absolute inset-0 z-40'
          style={{
            background: 'rgba(0, 0, 0, 0.45)',
          }}
        ></div>
        <div
          className='modal p-6 rounded-xl shadow-md z-50 w-[85%]'
          style={{
            position: 'absolute',
            background: '#fff',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <h1
            className='text-lg mb-4 font-semibold'
            style={{ fontFamily: 'sans-serif' }}
          >
            確認訂單修改內容
          </h1>

          <div className='bg-gray-100 rounded-xl px-4 mt-2.5 font-light  divide-y-2 divide-gray-300 divide-dashed min-h-56 max-h-56 overflow-y-scroll hide-scrollbar'>
            {editProducts?.map((res, index) => {
              const itemAmount =
                res?.mod_number === null
                  ? res?.amount
                  : +res?.price * res?.mod_number;

              changedAmount.current += itemAmount;

              return (
                <div
                  key={`item-${index}`}
                  className='flex flex-row justify-between items-center py-2'
                >
                  <div className='flex  flex-row items-center'>
                    <div className='flex-col  basis-auto'>
                      <span className='flex text-base'>{res?.name ?? ''}</span>
                      <span className='flex text-gray-400 text-base'>
                        {`$${res?.price ?? ''}`}
                      </span>
                    </div>
                  </div>
                  <div className='flex flex-col basis-auto items-end'>
                    <div className='flex flex-row'>
                      {res?.mod_number === null ? (
                        <span className='flex text-base '>{`x${res?.number}`}</span>
                      ) : (
                        <>
                          <span
                            className={`flex text-base line-through mr-2`}
                          >{`x${res?.number ?? 0}`}</span>
                          <span className='flex text-base '>{`x${res?.mod_number}`}</span>
                        </>
                      )}
                    </div>
                    <span className='flex text-green-600 text-base'>{`$${itemAmount}`}</span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className='bg-gray-100 rounded-xl p-4 py-2 mt-2.5'>
            <div className='flex flex-1 justify-between items-center'>
              <span className='text-sm'>原總計</span>
              <span>{`$${prevAmount?.toLocaleString()}`}</span>
            </div>
            <div className='flex flex-1 justify-between items-center'>
              <span className='text-sm'>修改後總計</span>
              <span className='text-green-600'>{`$${changedAmount.current?.toLocaleString()}`}</span>
            </div>
          </div>

          <div className='bg-bc-lightred rounded-xl p-4 mt-2.5'>
            <p className='text-xs text-bc-red'>
              上述項目只顯示調整過的項目，其餘維持原本的數量。
            </p>
            <p className='text-xs text-bc-red mt-1'>
              如修改訂單後有任何問題再請聯繫客服處理！
            </p>
          </div>

          <div className='flex flex-1 flex-row mt-5'>
            <button
              type='button'
              className='flex w-full py-2 bg-gray-300 rounded-md justify-center text-white  mr-5'
              onClick={back}
            >
              取消
            </button>
            <button
              type='button'
              className='flex w-full py-2 bg-bc-blue rounded-md justify-center text-white '
              onClick={save}
            >
              保存
            </button>
          </div>
        </div>
      </div>
      <Loading isLoading={isLoading} />
    </>
  );
};

export default EditOrderConfirm;
