import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

interface ControlButtonProps {
  value?: number;
  unit?: number;
}

type ControlButtonHandler = {
  getValue: () => number;
  setValue: (val: number) => void;
};

export const ControlButton = forwardRef<
  ControlButtonHandler,
  ControlButtonProps
>(({ value = 0, unit = 0 }, ref) => {
  const [number, setNumber] = useState<number>(value as number);

  const actionPress = (e) => {
    const dataAction = e.currentTarget.getAttribute('data-action');
    setNumber((prev: number) =>
      Math.max(
        dataAction === 'increment' ? prev + 1 * unit : prev - 1 * unit,
        0
      )
    );
  };

  useImperativeHandle(ref, () => ({
    getValue: () => number,
    setValue: (value) => {
      setNumber(value);
    },
  }));

  useEffect(() => {
    setNumber(value);
  }, [value]);

  return (
    <div className='flex flex-row h-11 w-full rounded-lg relative bg-transparent'>
      <button
        data-action='decrement'
        onClick={actionPress}
        className='bg-white text-gray-600 hover:text-gray-700  h-full w-20 rounded-l cursor-pointer outline-none items-center justify-center flex'
      >
        <svg
          className='w-3 h-3 text-gray-900'
          aria-hidden='true'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 18 2'
        >
          <path
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            d='M1 1h16'
          />
        </svg>
      </button>
      <input
        type='number'
        className='focus:outline-none text-center w-full bg-gray-100 font-semibold text-lg focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none'
        name='custom-input-number'
        value={number}
        onChange={(e) => {
          console.log('CHANGE');
          setNumber(parseInt(e.target.value, 10));
        }}
      ></input>
      <button
        data-action='increment'
        className='bg-gray text-gray-600 hover:text-gray-700  h-full w-20 rounded-r cursor-pointer items-center justify-center flex'
        onClick={actionPress}
      >
        <svg
          className='w-3 h-3 text-gray-900'
          aria-hidden='true'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 18 18'
        >
          <path
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            d='M9 1v16M1 9h16'
          />
        </svg>
      </button>
    </div>
  );
});
