import clsx from 'clsx';

export const Container = ({
  children,
  hasHeader = true,
  hideHeader = false,
  showBottomNav = false,
}) => {
  console.log(hasHeader);

  return (
    <div className='absolute h-full w-full overflow-hidden flex justify-center bg-slate-50'>
      <div className='max-width'>
        <div className={clsx({ 'page-header-wrapper': !hideHeader })}>
          {children}
        </div>
      </div>
    </div>
  );
};
