import * as React from "react";
function SvgUser(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <path d="M16.043 14H7.957A4.963 4.963 0 003 18.957V24h18v-5.043A4.963 4.963 0 0016.043 14z" />
      <circle cx={12} cy={6} r={6} />
    </svg>
  );
}
export default SvgUser;
