import React from 'react';

import { Container } from 'components';

const NoMatch: React.FC = () => {
  return (
    <Container>
      <p>NOT FOUND 404</p>
    </Container>
  );
};

export default NoMatch;
