import { Route, Routes } from 'react-router-dom';
import EditOrderConfirm from 'pages/modals/editOrderConfirm';
import ImageModal from 'pages/modals/imageModal';

import ModalRoutes from 'utils/modalRoutes';
import { Container } from 'components';

const ModalContainer = () => {
  return (
    <Routes>
      <Route path='/modal/:id' element={<EditOrderConfirm />} />
      <Route path='/modal_image' element={<ImageModal />} />
    </Routes>
  );
};

export default ModalContainer;
