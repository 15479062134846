export enum ROLE {
  WHOLE_DISTRICT_ADMIN = 1,
  NORTH_ADMIN = 2,
  CENTRAL_ADMIN = 3,
  SOUTH_ADMIN = 4,
  NORTH_FRANCHISEE = 5,
  CENTRAL_FRANCHISEE = 6,
  SOUTH_FRANCHISEE = 7,
  NORTH_EMPLOYEE = 8,
  CENTRAL_EMPLOYEE = 9,
  SOUTH_EMPLOYEE = 10,
  NORTH_DEALER = 11,
  CENTRAL_DEALER = 12,
  SOUTH_DEALER = 13,
}

export const ROLE_CONSTANT = new Map([
  [
    ROLE.WHOLE_DISTRICT_ADMIN,
    {
      priceKey: 'price_north',
      regionKey: 'north',
      roleKey: 'normal',
    },
  ],
  [
    ROLE.NORTH_ADMIN,
    {
      priceKey: 'price_north',
      regionKey: 'north',
      roleKey: 'normal',
    },
  ],
  [
    ROLE.CENTRAL_ADMIN,
    {
      priceKey: 'price_center',
      regionKey: 'central',
      roleKey: 'normal',
    },
  ],
  [
    ROLE.SOUTH_ADMIN,
    {
      priceKey: 'price_south',
      regionKey: 'south',
      roleKey: 'normal',
    },
  ],
  [
    ROLE.NORTH_FRANCHISEE,
    {
      priceKey: 'price_north',
      regionKey: 'north',
      roleKey: 'normal',
    },
  ],
  [
    ROLE.CENTRAL_FRANCHISEE,
    {
      priceKey: 'price_center',
      regionKey: 'central',
      roleKey: 'normal',
    },
  ],
  [
    ROLE.SOUTH_FRANCHISEE,
    {
      priceKey: 'price_south',
      regionKey: 'south',
      roleKey: 'normal',
    },
  ],
  [
    ROLE.NORTH_EMPLOYEE,
    {
      priceKey: 'price_employ_north',
      regionKey: 'north',
      roleKey: 'employee',
    },
  ],
  [
    ROLE.CENTRAL_EMPLOYEE,
    {
      priceKey: 'price_employ_center',
      regionKey: 'central',
      roleKey: 'employee',
    },
  ],
  [
    ROLE.SOUTH_EMPLOYEE,
    {
      priceKey: 'price_employ_south',
      regionKey: 'south',
      roleKey: 'employee',
    },
  ],

  [
    ROLE.NORTH_DEALER,
    {
      priceKey: 'price_dealer_south',
      regionKey: 'north',
      roleKey: 'dealer',
    },
  ],
  [
    ROLE.CENTRAL_DEALER,
    {
      priceKey: 'price_dealer_center',
      regionKey: 'central',
      roleKey: 'dealer',
    },
  ],
  [
    ROLE.SOUTH_DEALER,
    {
      priceKey: 'price_dealer_south',
      regionKey: 'south',
      roleKey: 'dealer',
    },
  ],
]);
