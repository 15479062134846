import { combineReducers } from 'redux';

import counter from 'redux/slices/counter';
import cart from 'redux/slices/cart';
import route from 'redux/slices/route';

import { store } from './store';

const rootReducer = combineReducers({ counter, cart, route });

export type RootState = ReturnType<typeof store.getState>;

export default rootReducer;
