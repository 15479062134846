import React, { CSSProperties } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

interface LineHeaderProps {
  title?: string;
  subtitle?: string;
  goBackText?: string;
  actionButtonText?: string;
  actionButtonStyle?: CSSProperties;
  actionButtonOnClick?: () => void;
}

export const LineHeader = ({
  title,
  subtitle,
  goBackText = '回上頁',
  actionButtonText,
  actionButtonStyle,
  actionButtonOnClick,
}: LineHeaderProps) => {
  const navigate = useNavigate();

  const currentRouteConfig = useSelector(
    (state: RootState) => state.rootReducer.route.currentRouteConfig
  );

  const canGoBack = window.history.state.idx !== 0;

  return (
    <div
      className={clsx(
        'fixed top-0 flex h-[75px] max-width border-b border-ea transition-colors duration-200 z-2 bg-white p-4 py-2 justify-between'
      )}
    >
      <div className='flex items-start flex-col'>
        <span
          className={clsx('flex text-2xl font-bold', {
            'text-2xl': title?.length < 10,
            'text-xl': title?.length > 11 && title?.length < 16,
            'text-lg': title?.length > 15,
          })}
        >
          {title}
        </span>
        <span className='flex text-base text-[#999]'>{subtitle}</span>
      </div>
      <div
        className={clsx('flex flex-row items-center justify-center', {
          'w-[90px]': !actionButtonText,
        })}
      >
        {currentRouteConfig?.allowGoBack && canGoBack ? (
          <button
            className={clsx('border py-1.5 px-5 rounded-3xl shadow-sm', {
              'mr-2': actionButtonText,
            })}
            onClick={() => {
              navigate(-1);
            }}
          >
            <span className='flex text-base text-[#666]'>{goBackText}</span>
          </button>
        ) : null}

        {actionButtonText ? (
          <button
            className='flex justify-center border py-1.5 px-5 rounded-3xl min-w-[90px]  bg-[#2196f3]'
            style={actionButtonStyle}
            onClick={actionButtonOnClick}
          >
            <span className='flex text-base text-white'>
              {actionButtonText}
            </span>
          </button>
        ) : null}
      </div>
    </div>
  );
};
