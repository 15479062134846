import React from 'react';

import { NoData as EmptyData } from 'components/icons';

export const NoData = () => {
  return (
    <div className='flex flex-1 flex-col items-center justify-center'>
      <EmptyData width={150} height={150} className={'mt-[-4.6rem]'} />
      <span className='text-lg text-bc-666 mt-4'>暫無資料</span>
    </div>
  );
};
