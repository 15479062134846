import { Loading } from 'components';
import Modal from 'components/modal';
import Toast from 'components/toast/index';
import dayjs from 'dayjs';
import { OrderStatusMap } from 'definitions/order';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  commonApi,
  useDeleteOrderMutation,
  useGetOrderQuery,
} from 'redux/services/common';
import { useAppDispatch } from 'redux/store';

const Skeleton = () => {
  return (
    <>
      <div className='relative flex-1  p-4 shadow-md rounded-lg bg-white mt-12 '>
        <div id='animate-container' className=' animate-pulse'>
          <div className='flex flex-row mb-4'>
            <div className='flex flex-1 flex-col leading-none'>
              <div className='h-[13px] bg-gray-200 rounded-full dark:bg-gray-300 w-48 mb-2 mt-1'></div>
              <div className='h-[13px] bg-gray-200 rounded-full dark:bg-gray-300 w-48 mb-4'></div>
            </div>

            <div className='flex basis-auto items-center justify-center'>
              <div className='h-[13px] bg-gray-200 rounded-full dark:bg-gray-300 w-24 right-4'></div>
            </div>
          </div>
          <div className='flex flex-row mb-4  justify-between'>
            <div className='h-[13px] bg-gray-200 rounded-full dark:bg-gray-200 w-48'></div>
            <div className='h-[13px] bg-gray-200 rounded-full dark:bg-gray-200 w-24'></div>
          </div>

          <div className='rounded-lg bg-gray-100 mt-2'>
            <div className='rounded-md h-[60px] bg-gray-200 rounded-xs dark:bg-gray-200 w-full'></div>
          </div>
        </div>
        <div className='flex flex-row justify-between mt-[11px]'>
          <button className='flex-1 bg-blue-500 text-white font-bold py-2 px-4 text-sm rounded-full mr-3'>
            修改
          </button>
          <button className='flex-1 bg-transparent text-red-500 font-semibold text-sm  py-2 px-4 border border-red-500  rounded-full'>
            刪除
          </button>
        </div>
      </div>
    </>
  );
};

const Order: React.FC = () => {
  const data = useParams();
  const navigate = useNavigate();
  const { state, ...location } = useLocation();
  const dispatch = useAppDispatch();

  const topRef = useRef(null);
  const listRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    text: '訂單已處理故無法刪除，若有問題請聯繫管理員協助處理。',
    show: false,
  });

  const { data: orderData, isLoading: orderDataLoading } = useGetOrderQuery(
    { id: +data?.orderId },
    { skip: !data?.orderId }
  );

  const [deleteOrder, { data: deleteResult, isSuccess, error, isLoading }] =
    useDeleteOrderMutation();

  const mapData = OrderStatusMap.get(orderData?.data?.attributes?.status);

  useEffect(() => {
    if (isSuccess) {
      if (deleteResult) {
        return Toast.info('訂單成功刪除', 2000, () => {
          dispatch(commonApi.util.resetApiState());
          navigate('/orders', { replace: true });
        });
      } else {
        return Toast.info(deleteResult?.msg, 2000);
      }
    }

    if (error) {
      const errorMessage =
        (error as { data?: { error: string } }).data?.error ?? '錯誤請稍後再試';

      return Toast.info(errorMessage, 2000);
    }
  }, [isSuccess, error]);

  useEffect(() => {
    setTimeout(() => {
      const topHeight = topRef.current?.offsetHeight;
      const remainingHeight = window.innerHeight - topHeight - 80;
      listRef.current.style.cssText = `max-height:${remainingHeight}px; height: ${remainingHeight}px;`;
    });
  }, [orderData]);

  const modify = () => {
    if (orderData?.data?.attributes?.status === 2)
      return Toast.info('訂單已完成，無法修改', 2000, () => {});

    const find = orderData?.data?.attributes?.detail?.find(
      (item) => item?.mod_number !== null
    );

    if (find) {
      return Toast.info('訂單已修改過，建議刪除重新下訂', 2000, () => {
        navigate('/orders');
      });
    }

    navigate(`/editOrder/${orderData?.data?.id}`, {
      state: orderData?.data,
    });
  };

  const onDelConfirm = async () => {
    setModalInfo((prev) => ({ ...prev, show: false }));

    if (state?.attributes?.status === 2) return;

    setTimeout(() => {
      deleteOrder(state?.id);
    }, 500);
  };

  const handleToggleDelModal = () => {
    setModalInfo((prev) => ({ ...prev, show: !prev.show }));
  };

  const deleteAction = () => {
    setModalInfo({
      text:
        state?.attributes?.status === 2
          ? '訂單已處理故無法刪除，若有問題請聯繫管理員協助處理。'
          : '確認是否要刪除此筆訂單',
      show: true,
    });
  };

  return (
    <div
      className='relative pb-[10px] pl-[30px] pr-[30px] overflow-x-hidden'
      style={{ backgroundColor: '#f7f7f7' }}
    >
      <div
        className={
          'absolute left-0 right-0 top-0 flex justify-center h-[50px] max-width  transition-colors duration-200 z-10'
        }
      >
        <div className='absolute top-0 bottom-0 flex pl-2 left-0'>
          {/* <button
            className={'font-bold'}
            onClick={(e) => {
              e.stopPropagation();
              navigate(-1);
            }}
          >
            <NavBack fontSize={24} />
          </button> */}
        </div>
        <div className='flex basis-auto items-center'>
          <span className='font-[600] text-[18px] font-mono'>訂單明細</span>
        </div>
        <div className='absolute top-0 bottom-0 flex pr-2 right-0'>
          <button
            onClick={(e) => {
              e.stopPropagation();
              navigate(-1);
            }}
          ></button>
        </div>
      </div>

      <>
        <div className='product-confirm-bg flex  justify-center absolute h-[250px]'></div>
        {orderDataLoading ? (
          <Skeleton />
        ) : (
          <div
            className='relative flex-1  p-4 shadow-md rounded-lg bg-white mt-12'
            ref={topRef}
          >
            <div className='flex flex-row mb-2'>
              <div className='flex flex-1 flex-col leading-none'>
                <h1 className='font-medium text-lg'>{`#${
                  orderData?.data?.id
                } - ${orderData?.data?.orderType?.name ?? ''}`}</h1>
                <span className=' text-gray-400 text-base '>
                  {dayjs(orderData?.data?.attributes?.updatedAt).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )}
                </span>
              </div>
              <div className='flex basis-auto items-center justify-center'>
                <div
                  className='px-4 py-1 rounded-full shadow'
                  style={{ backgroundColor: mapData?.backgroundColor }}
                >
                  <span
                    className='flex text-sm font-bold'
                    style={{ color: mapData?.textColor }}
                  >
                    {mapData?.text}
                  </span>
                </div>
              </div>
            </div>
            <div className='flex flex-row mb-1'>
              <div className='flex flex-1 items-center'>
                <span className='text-base'>{`${
                  orderData?.data?.attributes?.detail?.length ?? 0
                } 項商品`}</span>
              </div>
              <div className='flex basis-auto items-center justify-center'>
                <span className='text-2xl font-semibold'>{`$ ${orderData?.data?.attributes?.amount?.toLocaleString()}`}</span>
              </div>
            </div>

            <div className='rounded-lg bg-gray-100  p-2 min-h-[64px]'>
              <p className='text-slate-400'>備註：</p>
              <p className='text-slate-600 text-sm'>
                {orderData?.data?.attributes?.note ?? ''}
              </p>
            </div>
            <div className='flex flex-row justify-between mt-2'>
              <button
                className='flex-1 bg-blue-500 text-white font-bold py-2 px-4 text-sm rounded-full mr-3'
                onClick={modify}
              >
                修改
              </button>
              <button
                className='flex-1 bg-transparent text-red-500 font-semibold text-sm  py-2 px-4 border border-red-500  rounded-full'
                onClick={deleteAction}
              >
                刪除
              </button>
            </div>
          </div>
        )}
      </>

      <div className='pb-14 mt-4'>
        <div
          className='divide-y-2 rounded-md shadow divide-dashed bg-white overflow-y-scroll hide-scrollbar'
          ref={listRef}
        >
          {orderData?.data?.attributes?.detail?.map((product, index) => {
            const imageData = product?.thumbnail ?? '';
            const modifyNum = +product?.number - product?.mod_number;

            const numberStyle = product?.mod_number ? 'line-through mr-2' : '';

            const amount =
              product?.mod_number !== null
                ? +product?.price * modifyNum
                : product?.amount;

            const displayThumbnail =
              imageData.includes('.jpg') || imageData.includes('.png')
                ? imageData
                : imageData
                ? `${imageData}.jpg`
                : '';

            return (
              <div
                key={`item-${index}`}
                className='flex flex-row justify-between p-3 coupon h-fit'
              >
                <div className='flex  flex-row items-center'>
                  {displayThumbnail !== '' ? (
                    <img
                      className='flex w-[80px] h-16 rounded mr-2'
                      src={displayThumbnail}
                      data-lg-size='1024-800'
                      onClick={(e) => {
                        e.stopPropagation();

                        if (!imageData) return;

                        navigate(`/modal_image`, {
                          state: {
                            background: true,
                            previousLocation: location,
                            image: displayThumbnail,
                          },
                        });
                      }}
                    ></img>
                  ) : (
                    <div className='flex items-center justify-center w-[80px] h-16 bg-gray-300 rounded mr-2'>
                      <svg
                        className='w-6 h-6 text-gray-500 '
                        aria-hidden='true'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='currentColor'
                        viewBox='0 0 20 18'
                      >
                        <path d='M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z' />
                      </svg>
                    </div>
                  )}

                  {/* <div className='flex rounded-full w-12 h-12 bg-gray-200 basis-auto mr-2' /> */}
                  <div className='flex-col  basis-auto'>
                    <span className='flex text-lg'>{product?.name ?? ''}</span>
                    <span className='flex text-gray-400 text-lg'>
                      {`$${product?.price ?? ''}`}
                    </span>
                  </div>
                </div>
                <div className='flex flex-col basis-auto items-end'>
                  <div className='flex flex-row'>
                    <span className={`flex text-lg ${numberStyle}`}>{`x${
                      product?.number ?? 0
                    }`}</span>
                    {product?.mod_number ? (
                      <span className='flex text-lg '>{`x${product?.mod_number}`}</span>
                    ) : null}
                  </div>
                  <span className='flex text-green-600 text-lg'>{`$${product?.amount}`}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Modal
        isOpen={modalInfo.show}
        setIsOpen={handleToggleDelModal}
        content={modalInfo.text}
        onConfirm={onDelConfirm}
      />
      <Loading isLoading={isLoading} />
    </div>
  );
};

export default Order;
