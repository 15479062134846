import React, { useEffect, useRef, useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { NavBack } from 'components/icons';

interface HeaderProps {
  title?: string;
  isTransparent?: boolean;
  goBack?: () => void;
  rightButtonText?: string;
  rightButtonOnClick?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  title = '',
  isTransparent = false,
  goBack = undefined,
  rightButtonText = '',
  rightButtonOnClick = () => {},
}) => {
  const navigate = useNavigate();
  const canGoBack = window.history.state.idx !== 0;
  const [transColor, setTransColor] = useState(false);

  const bgRef = useRef(null);

  useEffect(() => {
    document.addEventListener('scroll', function () {
      // Get the scroll position
      let scrollPos = window.pageYOffset;

      if (scrollPos) {
        setTransColor(true);
      } else {
        setTransColor(false);
      }
    });
  }, []);
  return (
    <div
      className={clsx(
        'fixed top-0 flex justify-center h-[50px] max-width border-b border-ea transition-colors duration-200 z-2',
        {
          'bg-white drop-shadow': !isTransparent,
          'bg-[#07a45b]': transColor,
        }
      )}
    >
      <div className='absolute top-0 bottom-0 flex pl-2 left-0'>
        {canGoBack ? (
          <button
            className={clsx('font-bold', { 'text-white': isTransparent })}
            onClick={(e) => {
              if (goBack) {
                goBack();
                return;
              }

              e.stopPropagation();
              navigate(-1);
            }}
          >
            <NavBack fontSize={24} />
          </button>
        ) : null}
      </div>
      <div className='flex basis-auto items-center'>
        <span
          className={clsx('font-[600] text-[18px] font-mono', {
            'text-white': isTransparent,
          })}
        >
          {title}
        </span>
      </div>
      {rightButtonText ? (
        <div className='absolute top-0 bottom-0 flex right-0 pr-[15px]'>
          <button
            onClick={(e) => {
              e.stopPropagation();

              rightButtonOnClick && rightButtonOnClick();
            }}
            className={clsx('font-bold', { 'text-white': isTransparent })}
          >
            {rightButtonText}
          </button>
        </div>
      ) : null}
    </div>
  );
};
