import * as React from "react";
function SvgShowPwd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#DDD" fillRule="evenodd">
        <path d="M12 5.524c2.358 0 4.503.975 6.204 2.606 1.35 1.294 2.25 2.905 2.258 3.724v.299c-.007.813-.909 2.423-2.258 3.717-1.702 1.631-3.846 2.606-6.204 2.606-2.352 0-4.497-.987-6.208-2.638-1.346-1.299-2.253-2.913-2.253-3.685v-.305c0-.772.907-2.387 2.253-3.686C7.502 6.512 9.648 5.524 12 5.524M12 4C9.217 4 6.7 5.159 4.719 7.07 3.103 8.63 2 10.593 2 11.849v.305c0 1.255 1.104 3.218 2.719 4.777C6.699 18.84 9.217 20 12 20c2.786 0 5.301-1.144 7.274-3.035 1.618-1.552 2.715-3.512 2.726-4.806v-.311c-.01-1.3-1.108-3.26-2.726-4.813C17.3 5.144 14.785 4 12 4" />
        <path d="M12 9.6a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8M12 8a4 4 0 100 8 4 4 0 000-8" />
      </g>
    </svg>
  );
}
export default SvgShowPwd;
