import React from 'react';

import { Container } from 'components';
import { MenuBill, MenuOrder, MenuMember, Next } from 'components/icons';
import { Link } from 'react-router-dom';
import { LineHeader } from 'components/header/lineHeader';

const My: React.FC = () => {
  return (
    <>
      <LineHeader />

      <div className='flex flex-1 flex-col pb-14 '>
        {/* <div
          className='flex bg-gradientbg h-[200px] items-center justify-center'
          style={{
            borderBottomRightRadius: '20px 10px',
            borderBottomLeftRadius: '20px 10px',
          }}
        >
          <div className='flex flex-col  items-center justify-center'>
            <div className='w-[80px] h-[80px] rounded-full bg-slate-300 shadow'></div>
            <span className='text-lg font-bold mt-2 text-bc-333'>
              台北車站店
            </span>
          </div>
        </div> */}
        <div className='flex flex-row rounded-md shadow bg-white p-4  m-4 mb-0'>
          <div className='basis-auto flex items-center mr-2'>
            <div className='w-[50px] h-[50px] rounded-full bg-slate-300 shadow'></div>
          </div>
          <div className='flex flex-col'>
            <h1 className='text-lg text-bc-333'>小明</h1>
            <span className='text-bc-999'>台北車站店</span>
          </div>
        </div>

        <div className='m-4 p-[10px] rounded-md shadow bg-white '>
          <Link
            to={'/orders'}
            className='flex flex-row items-center justify-between'
          >
            <div className='flex flex-row'>
              <MenuOrder width={24} height={24} className='mr-[5px]' />
              <span className='text-base'>我的訂單</span>
            </div>
            <Next />
          </Link>
          <hr className='my-[10px] h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-bc-ddd to-bc-ddd opacity-40 dark:opacity-100 -mr-[10px]' />

          <Link
            to={'/bills'}
            className='flex flex-row items-center justify-between'
          >
            <div className='flex flex-row'>
              <MenuBill width={24} height={24} className='mr-[5px]' />
              <span className='text-base'>我的帳單</span>
            </div>
            <Next />
          </Link>

          <hr className='my-[10px] h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-bc-ddd to-bc-ddd opacity-40 dark:opacity-100 -mr-[10px]' />

          <Link
            to={'/my/center'}
            className='flex flex-row items-center justify-between'
          >
            <div className='flex flex-row'>
              <MenuMember width={24} height={24} className='mr-[5px]' />

              <span className='text-base'>會員中心</span>
            </div>
            <Next />
          </Link>
        </div>
      </div>
    </>
  );
};

export default My;
