import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import store from 'redux/store';
import 'styles/global.scss';
import './i18n';

import Home from 'pages/home';
import My from 'pages/my';
import Orders from 'pages/orders';
import Order from 'pages/orders/order';
import EditOrder from 'pages/orders/editOrder';

import NoMatch from 'pages/nomatch';
import Login from 'pages/login';
import Product from 'pages/products';
import ConfirmOrder from 'pages/orders/confirmOrder';

import Landing from 'pages/landing';

import Bill from 'pages/bill';
import BillDetail from 'pages/bill/detail';
import MemberCenter from 'pages/my/center';
import EditPassword from 'pages/my/editPassword';

import { Link, Routes, Route, useLocation } from 'react-router-dom';
import ProtectedRoutes from 'utils/protectedRoutes';

import EditOrderConfirm from 'pages/modals/editOrderConfirm';
import ProductConfirmOrder from 'pages/products/confirmOrder';

import toast, { Toaster } from 'react-hot-toast';

import ModalContainer from 'ModalContainer';
import RouteLayout from 'utils/RouteLayout';

interface RouteConfig {
  path: string;
  element: React.ReactNode;
  showBottomNav?: boolean;
  showHeader?: boolean;
  allowGoBack?: boolean;
}

const routeConfig: RouteConfig[] = [
  { path: '/', element: <Home />, showBottomNav: false, allowGoBack: false },
  {
    path: '/home',
    element: <Home />,
    showBottomNav: false,
    showHeader: true,
    allowGoBack: false,
  },
  { path: '/my', element: <My />, showBottomNav: true },
  {
    path: '/bills',
    element: <Bill />,
    showBottomNav: false,
    allowGoBack: false,
  },
  {
    path: '/orders',
    element: <Orders />,
    showBottomNav: false,
    allowGoBack: false,
  },
  { path: '/editOrder/:orderId', element: <EditOrder />, showBottomNav: false },
  { path: '/product/:productId', element: <Product />, showBottomNav: false },
  {
    path: '/product/confirmOrder',
    element: <ProductConfirmOrder />,
    showBottomNav: false,
    showHeader: false,
  },
  {
    path: '/order/:orderId',
    element: <Order />,
    showBottomNav: false,
    showHeader: false,
  },
  {
    path: '/bill/:billId/detail',
    element: <BillDetail />,
    showBottomNav: false,
    showHeader: false,
  },
  {
    path: '/my/center',
    element: <MemberCenter />,
    showBottomNav: false,
  },
  {
    path: '/my/editPassword',
    element: <EditPassword />,
    showBottomNav: false,
  },
];

function App(): JSX.Element {
  const queryClient = new QueryClient();

  const location = useLocation();
  const previousLocation = location.state?.previousLocation;

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Toaster
          containerStyle={{
            top: '50%',
          }}
        />

        <Routes location={previousLocation || location}>
          <Route element={<ProtectedRoutes />}>
            {routeConfig.map(
              ({
                path,
                element,
                showBottomNav = true,
                showHeader = true,
                allowGoBack = true,
              }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <RouteLayout
                      path={path}
                      showHeader={showHeader}
                      showBottomNav={showBottomNav}
                      allowGoBack={allowGoBack}
                    >
                      {element}
                    </RouteLayout>
                  }
                />
              )
            )}
          </Route>
          <Route path='/auth/login' element={<Login />} />
          <Route element={<Landing />} path='/landing' />
          <Route path='*' element={<NoMatch />} />
        </Routes>

        {previousLocation && <ModalContainer />}
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
