import { ROLE_CONSTANT } from 'constant';

export default function () {
  const userInfo = JSON.parse(localStorage.getItem('info'));

  const userConstant = ROLE_CONSTANT.get(+userInfo?.role?.id);

  return {
    userInfo,
    userConstant,
  };
}
