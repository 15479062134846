export enum BillStatus {
  UNSETTLE = 0,
  SETTLE = 1,
}
export type StatusMapType = {
  text: string;
  colorName: string;
  textColor: string;
  backgroundColor: string;
  shadow: string;
  value: number;
};

export const BillStatusMap: Map<BillStatus, StatusMapType> = new Map([
  [
    BillStatus.UNSETTLE,
    {
      text: '待結清',
      textColor: '#ffa66f',
      backgroundColor: '#fff6eb',
      shadow: 'rgb(255, 166, 111,0.2) 1px 2px 2px 0px',
      colorName: 'warning',
      value: 0,
    },
  ],
  [
    BillStatus.SETTLE,
    {
      text: '已結清',
      textColor: '#3ed085',
      backgroundColor: '#f1fef4',
      shadow: 'rgb(62, 208, 133,0.2) 1px 2px 2px 0px',
      colorName: 'success',
      value: 1,
    },
  ],
]);
