import React from 'react';

interface ModalProps {
  title?: string;
  content?: string;
  showCancel?: boolean;
  isOpen: boolean;
  setIsOpen: (status) => void;
  onConfirm?: () => void;
}

/**
 * Primary UI component for user interaction
 */
const Modal = ({
  title,
  content,
  showCancel = true,
  setIsOpen,
  isOpen,
  onConfirm = () => {},
}: ModalProps) => {
  const handleClose = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={`h-dvh absolute top-0 bottom-0 right-0 left-0 z-[90] bg-black/[0.6] flex flex-col justify-center items-center`}
      onClick={handleClose}
    >
      <div className='relative  w-full max-w-[19rem] max-h-full min-w-19rem'>
        <div className='relative bg-white rounded-lg shadow'>
          <div className='px-4 py-10'>
            {title && (
              <div className='flex items-center justify-center  rounded-t'>
                <h3 className='text-xl font-semibold text-gray-900'>{title}</h3>
              </div>
            )}

            <p
              className='text-center leading-relaxed text-333 whitespace-normal break-all '
              style={{ whiteSpace: 'pre-line' }}
            >
              {content}
            </p>
          </div>

          <div className='flex items-center  border-t border-e6  rounded-b'>
            {showCancel && (
              <button className='py-2.5 flex-1 text-center border-r'>
                取消
              </button>
            )}

            <button
              className='py-2.5 flex-1 text-center text-primary font-semibold text-red-500'
              onClick={() => {
                onConfirm && onConfirm();
                setIsOpen(false);
              }}
            >
              確定
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
