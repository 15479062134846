import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import mergeWith from 'lodash/mergeWith';
import isNumber from 'lodash/isNumber';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Header, Loading } from 'components';
import { useGetIngredientQuery } from 'redux/services/common';
import { useUser } from 'hooks';
import clsx from 'clsx';
import Modal from 'components/modal';
import { LineHeader } from 'components/header/lineHeader';

const EditOrder: React.FC = () => {
  const { state, ...location } = useLocation();
  const { userInfo, userConstant } = useUser();
  const navigate = useNavigate();

  const [modalInfo, setModalInfo] = useState({
    text: '確認訂單修改？ 若超過五分鐘則麻煩聯絡管理員處理。',
    show: false,
  });

  const [qureyParams, setQueryParams] = useState({
    typeId: state?.orderType?.id ?? 0,
    _start: 0,
    _end: 200,
    pageSize: 200,
    currentPage: 1,
  });

  const {
    data: ingredients,
    isLoading: ingredientLoading,
    isSuccess: ingredientSuccess,
  } = useGetIngredientQuery(qureyParams);

  const copyProducts = cloneDeep(state?.attributes?.detail);
  const [productItems, setProductItems] = useState(copyProducts);

  useEffect(() => {
    if (ingredientSuccess) {
      const copyIngredients = cloneDeep(ingredients?.data);

      for (let selectedItem of copyProducts) {
        const indexToUpdate = copyIngredients.findIndex(
          (item) => item.id === selectedItem?.id
        );

        copyIngredients[indexToUpdate] = {
          ...copyIngredients[indexToUpdate],
          number: selectedItem?.number,
          prevNumber: selectedItem?.number ?? 0,
        };
      }

      setProductItems(copyIngredients);
    }
  }, [ingredients]);

  const updateItemNumber = (
    index: number,
    action: 'INCREMENT' | 'DECREMENT'
  ) => {
    const tmp = [...productItems];

    const updatedNumber = Math.max(
      action === 'INCREMENT'
        ? (tmp[index]?.number ?? 0) + 1 * tmp[index]?.unit
        : (tmp[index]?.number ?? 0) - 1 * tmp[index]?.unit,
      0
    );

    const fixedNumber = updatedNumber < 0 ? 0 : updatedNumber;

    tmp[index] = {
      ...tmp[index],
      number: fixedNumber,
      mod_number: fixedNumber,
    };

    setProductItems(tmp);
  };

  const submit = async () => {
    if (state?.attributes?.status === 2) return;

    const tmp = [];
    let orderSum = 0;

    productItems?.map((item) => {
      if (item?.preNumber > 0 || item?.number > 0) {
        const amount =
          item?.price?.[userConstant.roleKey]?.[userConstant?.regionKey] *
          item?.number;

        tmp.push({
          id: item.id,
          name: item.name,
          thumbnail: item.thumbnail,
          price: item?.price?.[userConstant.roleKey]?.[userConstant?.regionKey],
          number: item.prevNumber ?? 0,
          mod_number: item?.mod_number ?? null,
          amount,
        });

        orderSum += amount;
      }
    });

    const orderInfo = {
      order: tmp,
      sum: orderSum,
      typeId: state?.orderType?.id,
      note: 'dfsdfsdfsdf test',
    };

    navigate(`/modal/123`, {
      state: {
        background: true,
        previousLocation: location,
        editProducts: tmp,
        amount: orderSum,
        orderTypeId: state?.orderType?.id,
        id: state?.id,
        prevAmount: state?.attributes?.amount,
      },
    });
  };

  return (
    <>
      <LineHeader
        title='修改訂單'
        subtitle='編輯訂單相關內容'
        actionButtonText='送出'
        actionButtonOnClick={submit}
      />

      <div className='list-wrapper bg-[#f7f7f7]'>
        <div className='grid grid-cols-1 divide-y-2 rounded-md shadow divide-dashed bg-white'>
          {productItems?.map((product, index) => {
            const imageData = product?.thumbnail ?? '';

            const displayThumbnail =
              imageData.includes('.jpg') || imageData.includes('.png')
                ? imageData
                : imageData
                ? `${imageData}.jpg`
                : '';

            const price =
              product?.price?.[userConstant.roleKey]?.[userConstant?.regionKey];

            const modifyNum = +product?.number - +product?.mod_number;

            const numberStyle = product?.mod_number ? 'line-through mr-2' : '';

            const amount =
              product?.mod_number >= 0
                ? +product?.price * modifyNum
                : product?.amount;

            return (
              <div
                key={`item-${index}`}
                className={clsx(
                  'flex flex-row justify-between items-center p-3 coupon',
                  {
                    'bg-[#FFFBDB]': product?.number > 0,
                  }
                )}
              >
                <div className='flex  flex-row items-center'>
                  {displayThumbnail !== '' ? (
                    <img
                      className='flex w-[80px] h-16 rounded mr-2'
                      src={displayThumbnail}
                      data-lg-size='1024-800'
                      onClick={(e) => {
                        e.stopPropagation();

                        if (!imageData) return;

                        navigate(`/modal_image`, {
                          state: {
                            background: true,
                            previousLocation: location,
                            image: displayThumbnail,
                          },
                        });
                      }}
                    ></img>
                  ) : (
                    <div className='flex items-center justify-center w-[80px] h-16 bg-gray-300 rounded mr-2'>
                      <svg
                        className='w-6 h-6 text-gray-500 '
                        aria-hidden='true'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='currentColor'
                        viewBox='0 0 20 18'
                      >
                        <path d='M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z' />
                      </svg>
                    </div>
                  )}

                  <div className='flex-col  basis-auto'>
                    <span className='flex text-base'>
                      {product?.name ?? ''}
                    </span>
                    <span className='flex text-gray-400 text-lg'>
                      {`$${price ?? ''}`}
                    </span>
                  </div>
                </div>

                <div className='custom-number-input h-10 w-32'>
                  <div className='flex flex-row h-10 w-full rounded-lg relative bg-transparent'>
                    <button
                      data-action='decrement'
                      className=' bg-gray-300 text-gray-600 hover:text-gray-700  h-full w-20 rounded-l cursor-pointer outline-none'
                      onClick={() => updateItemNumber(index, 'DECREMENT')}
                    >
                      <span className='m-auto text-2xl font-thin'>−</span>
                    </button>
                    <input
                      type='number'
                      className='outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none'
                      name='custom-input-number'
                      value={product?.number ?? 0}
                    ></input>
                    <button
                      data-action='increment'
                      className='bg-gray-300 text-gray-600 hover:text-gray-700  h-full w-20 rounded-r cursor-pointer'
                      onClick={() => updateItemNumber(index, 'INCREMENT')}
                    >
                      <span className='m-auto text-2xl font-thin'>+</span>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* <Loading isLoading={isLoading} /> */}
    </>
  );
};

export default EditOrder;
