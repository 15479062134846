export enum OrderStatus {
  Waiting = 0,
  Execute = 1,
  Completed = 2,
}
export type StatusMapType = {
  text: string;
  colorName: string;
  textColor: string;
  backgroundColor: string;
  shadow: string;
};

export const OrderStatusMap: Map<OrderStatus, StatusMapType> = new Map([
  [
    OrderStatus.Waiting,
    {
      text: '等待中',
      textColor: '#ffa66f',
      backgroundColor: '#fff6eb',
      shadow: 'rgb(255, 166, 111,0.2) 1px 2px 2px 0px',
      colorName: 'warning',
    },
  ],
  [
    OrderStatus.Execute,
    {
      text: '處理中',
      textColor: '#5990ff',
      backgroundColor: '#f5f7ff',
      shadow: 'rgb(89, 144, 255,0.2) 1px 2px 2px 0px',
      colorName: 'processing',
    },
  ],
  [
    OrderStatus.Completed,
    {
      text: '已完成',
      textColor: '#3ed085',
      backgroundColor: '#f1fef4',
      shadow: 'rgb(62, 208, 133,0.2) 1px 2px 2px 0px',
      colorName: 'success',
    },
  ],
]);
