import React, { useEffect, useState, useRef } from 'react';

import { CategoryCard } from 'components/categoryCard/indetx';

import { useNavigate } from 'react-router-dom';
import { Header, NoData } from 'components';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { LineHeader } from 'components/header/lineHeader';
import BillList from './billList';
import { BillStatus } from 'definitions/bill';

const Bill: React.FC = () => {
  const navigate = useNavigate();
  const settleRef = useRef();
  const unsettleRef = useRef();

  const [isSettled, setIsSettled] = useState(true);

  const [app, setApp] = useState('b');

  return (
    <>
      <div className='flex flex-col h-full bg-inherit'>
        <div className='flex top-0 z-10 bg-inherit relative w-full justify-center'>
          <LineHeader title={'帳單'} subtitle={'帳單列表一覽'} />
          <div
            className='flex flex-row  top-20  shadow-lg slider-container'
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#fff',
              zIndex: 1,
            }}
          >
            <div
              id='slider'
              style={{ height: 'inherit' }}
              className={clsx(
                'absolute inset-y-0 w-1/2 px-4 py-1 transition-transform transform bg-sky-500 z-0 ',
                {
                  'translate-x-0': app === 'b',
                  'translate-x-full': app === 'c',
                }
              )}
            >
              <div
                className={clsx(
                  'w-full h-full rounded-md',
                  {
                    active: app === 'light',
                    'bg-gray-600': app === 'dark',
                  },
                  {
                    // needs to be separate object otherwise dark/light & system keys overlap resulting in a visual bug
                    ['bg-gray-600']: app === 'system',
                  }
                )}
              ></div>
            </div>

            <button
              className='flex flex-1 items-center justify-center z-10'
              onClick={() => {
                setIsSettled(true);

                setApp('b');
              }}
            >
              <span
                className={clsx('font-bold', {
                  'text-black': app !== 'b',
                  'text-white': app === 'b',
                })}
              >
                已結
              </span>
            </button>

            <button
              className='flex flex-1 items-center justify-center z-10'
              onClick={() => {
                setIsSettled(false);
                setApp('c');
              }}
            >
              <span
                className={clsx('font-bold', {
                  'text-black': app !== 'c',
                  'text-white': app === 'c',
                })}
              >
                未結
              </span>
            </button>
          </div>
        </div>

        <div role={'tabpanel'} className='h-full'>
          <div
            className={clsx('tabPanel flex h-full', {
              active: isSettled === true,
            })}
          >
            <BillList status={BillStatus.SETTLE} />
          </div>
          <div
            className={clsx('tabPanel flex h-full', {
              active: isSettled === false,
            })}
          >
            <BillList status={BillStatus.UNSETTLE} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Bill;
