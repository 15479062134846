import React, { useEffect, useState } from 'react';

const ToastBox = (notice) => {
  const transitionTime = 300;
  const [notices, setNotices] = useState([]);

  const getNoticeKey = () => `notice-${new Date().getTime()}-${notices.length}`;

  const addNotice = (notice) => {
    const newNotice = { ...notice, key: getNoticeKey() };
    setNotices([newNotice]);

    if (newNotice.duration > 0) {
      setTimeout(() => {
        removeNotice(newNotice.key);
      }, newNotice.duration);
    }

    return () => {
      removeNotice(newNotice.key);
    };
  };

  const removeNotice = (key) => {
    setNotices((prevNotices) =>
      prevNotices.filter((notice) => {
        if (notice.key === key) {
          if (notice.onClose) {
            setTimeout(notice.onClose, transitionTime);
          }
          return false;
        }
        return true;
      })
    );
  };

  useEffect(() => {
    addNotice(notice);
  }, [notice]);

  return (
    <div className='toast'>
      {notices.map((notice) => (
        <div className='toast_bg' key={notice.key}>
          <div className='toast_box'>
            <div className='toast_text'>{notice.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ToastBox;
