import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  Route,
  Link,
  Routes,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Container } from 'components';

import {
  useGetCategoriesQuery,
  useGetIngredientQuery,
} from 'redux/services/common';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

import { useUser } from 'hooks';
import cloneDeep from 'lodash/cloneDeep';

import { addToCart } from 'redux/actions';
import { useAppDispatch } from 'redux/store';
import { Header, ControlButton, Loading } from 'components';
import { Placeholder } from 'components/icons';
import clsx from 'clsx';
import { RootState } from 'redux/reducers';
import { useSelector } from 'react-redux';
import Modal from 'components/modal';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { LineHeader } from 'components/header/lineHeader';
import Toast from 'components/toast';

const Products: React.FC = () => {
  const data = useParams();

  const navigate = useNavigate();
  const { state, ...location } = useLocation();
  const dispatch = useAppDispatch();
  const sheetRef = useRef<BottomSheetRef>(null);
  const controlButtonRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [navState, setNavState] = useState(null);

  const { userInfo, userConstant } = useUser();
  const [selectedItem, setSelectedItem] = useState(null);

  const [qureyParams, setQueryParams] = useState({
    typeId: +(data?.productId ?? 0),
    _start: 0,
    _end: 200,
    pageSize: 200,
    currentPage: 1,
  });

  const {
    data: ingredients,
    isLoading: ingredientLoading,
    isSuccess: ingredientSuccess,
  } = useGetIngredientQuery(qureyParams);
  const [productItems, setProductItems] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);

  const cartItems = productItems?.filter((item) => item?.number > 0) ?? [];

  const scrollRef = useBottomScrollListener(() => {
    if (ingredients?.pagination?.total > productItems?.length)
      setQueryParams((prev) => ({
        ...prev,
        _start: prev._end,
        _end: prev._end + prev.pageSize,
      }));
  });

  const orderItems = useSelector(
    (state: RootState) => state.rootReducer.cart.cartOrder
  );

  useEffect(() => {
    setNavState(state);

    if (ingredientSuccess) {
      if (productItems?.length) {
        setProductItems((prev) => [...prev, ...ingredients?.data]);
      } else {
        setProductItems(ingredients?.data);
      }
    }
  }, [ingredients]);

  useEffect(() => {
    if (!orderItems?.length || !productItems?.length) return;

    const products = cloneDeep(productItems);

    for (let selectedItem of orderItems) {
      const indexToUpdate = products.findIndex(
        (item) => item.id === selectedItem?.id
      );

      products[indexToUpdate] = {
        ...products?.[indexToUpdate],
        number: selectedItem?.number,
      };
    }

    setProductItems(products);
  }, [orderItems?.length, productItems?.length]);

  const addConfirm = () => {
    if (controlButtonRef?.current?.getValue() < 0) {
      return Toast.info('數量不能小於0', 2000);
    }
    const cloneData = cloneDeep(productItems);

    const indexToUpdate = productItems.findIndex(
      (item) => item.id === selectedItem?.id
    );

    if (indexToUpdate !== -1) {
      cloneData[indexToUpdate] = {
        ...cloneData[indexToUpdate],
        number: controlButtonRef?.current?.getValue(),
      };
    }

    setProductItems(cloneData);
    setOpen(false);
    controlButtonRef.current?.setValue(0);
  };

  const CartButton = useCallback(() => {
    // const cartItems = productItems?.filter((item) => item?.number > 0) ?? [];

    return (
      <div
        className={clsx(
          'absolute left-0 bottom-0 right-0 px-4 pb-10 transition-opacity',
          {
            'ease-in duration-400 opacity-100 visible': cartItems?.length,
            'ease-out duration-200 opacity-0 invisible':
              cartItems?.length === 0,
          }
        )}
      >
        <button
          onClick={async () => {
            await dispatch(addToCart(cartItems));
            navigate('/product/confirmOrder');
            // setConfirmBSOpen(true);
          }}
          className='flex w-full rounded-full bg-black  shadow-lg shadow-gray-700 p-4 justify-center opacity-80'
        >
          <span className='text-white text-xl'>{`查看購物車 ( ${
            orderItems?.length || cartItems?.length
          } )`}</span>
        </button>
      </div>
    );
  }, [cartItems, orderItems]);

  const bottomSheetDismiss = () => {
    if (open) setOpen(false);
  };

  const onDelConfirm = async () => {
    await dispatch(addToCart([]));
    navigate(-1);
  };

  const handleToggleDelModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <LineHeader
        title={navState?.typeName}
        subtitle={`共 ${productItems?.length} 項商品`}
        goBackText={'回分類'}
      />
      {/* <Header
        title={state?.typeName ?? ''}
        goBack={() => {
          if (orderItems?.length === 0 || !orderItems) {
            navigate(-1);
            return;
          }

          setShowModal(true);
        }}
      /> */}
      <div
        className={clsx('list-wrapper', {
          'pb-3': cartItems?.length === 0,
          'pb-28': cartItems?.length,
        })}
        ref={scrollRef as React.RefObject<HTMLDivElement>}
      >
        <ul className='space-y-3'>
          {productItems?.map((item, index) => {
            const itemAmount = Math.ceil(
              item?.number *
                item?.price?.[userConstant.roleKey]?.[userConstant?.regionKey]
            );

            const displayThumbnail =
              item?.thumbnail.includes('.jpg') ||
              item?.thumbnail.includes('.png')
                ? item?.thumbnail
                : item?.thumbnail
                ? `${item?.thumbnail}.jpg`
                : '';

            return (
              <li
                key={`${item}-${index}`}
                className='flex items-center rounded bg-white  shadow py-2'
                onClick={(e) => {
                  e.preventDefault();

                  const pressedItem = productItems
                    .filter((productItem) => productItem?.id === item?.id)
                    .at(0);

                  setSelectedItem(pressedItem);
                  setTimeout(() => {
                    setOpen((prev) => !prev);
                  }, 100);
                }}
              >
                {displayThumbnail !== '' ? (
                  <img
                    className='flex w-[80px] h-16 rounded ml-2'
                    src={displayThumbnail}
                    data-lg-size='1024-800'
                    onClick={(e) => {
                      e.stopPropagation();

                      if (!item?.thumbnail) return;

                      navigate(`/modal_image`, {
                        state: {
                          background: true,
                          previousLocation: location,
                          image: displayThumbnail,
                        },
                      });
                    }}
                  ></img>
                ) : (
                  <div className='flex items-center justify-center w-[80px] h-16 bg-gray-300 rounded ml-2'>
                    <svg
                      className='w-6 h-6 text-gray-500 '
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      viewBox='0 0 20 18'
                    >
                      <path d='M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z' />
                    </svg>
                  </div>
                )}

                {/* content */}
                <div className='flex flex-1 justify-between items-center px-3'>
                  <div className='flex flex-col'>
                    <div className='flex flex-row items-center'>
                      <span className='flex items-center text-base font-medium text-gray-900 antialiased'>
                        {item?.name?.replace(' ', '')}
                      </span>
                      <span className='text-blue-500 ml-2 text-xs'>
                        {item?.note}
                      </span>
                    </div>

                    <span className='flex items-center text-lg font-medium text-gray-400 '>{`$${item?.price?.[
                      userConstant.roleKey
                    ]?.[userConstant?.regionKey]?.toLocaleString()}`}</span>
                  </div>
                  {item?.number ? (
                    <div className='flex flex-col basis-auto items-end pl-1'>
                      <span className='flex text-lg'>{`x${item?.number}`}</span>
                      <span className='flex text-green-600 text-lg'>{`$${itemAmount?.toLocaleString()}`}</span>
                    </div>
                  ) : null}
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <CartButton />
      <BottomSheet
        ref={sheetRef}
        open={open}
        // scrollLocking
        onDismiss={() => {
          if (open) setOpen(false);
          // if (confirmBSOpen) setConfirmBSOpen(false);
        }}
        maxHeight={window.innerHeight * 0.9}
      >
        <div className='test' style={{ height: '300px' }}>
          <div className='flex flex-col items-center pt-5'>
            {/* <img
                  className='w-24 h-24 mb-3 rounded-full shadow-lg'
                  src='/docs/images/people/profile-picture-3.jpg'
                  alt={selectedItem?.name}
                /> */}
            <div
              className='w-24 h-24 mb-3 rounded-full shadow-lg items-center justify-center flex'
              style={{ backgroundColor: '#e9e9e9' }}
            >
              <Placeholder width={48} height={48} className='fill-bc-666' />
            </div>

            <h5 className='mb-1 text-xl font-medium text-gray-900'>
              {selectedItem?.name ?? ''}
            </h5>
            <span className='text-sm text-gray-500 dark:text-gray-400'>
              {selectedItem?.note ?? ''}
            </span>

            <span className='text-base text-gray-600 dark:text-gray-400'>
              {selectedItem?.price?.[userConstant.roleKey]?.[
                userConstant?.regionKey
              ] ?? ''}
            </span>

            <div className='custom-number-input h-11 mt-5 border-gray-400 border rounded-lg overflow-hidden'>
              <ControlButton
                ref={controlButtonRef}
                value={selectedItem?.number ?? 0}
                unit={selectedItem?.unit ?? 0}
              />
            </div>
          </div>
        </div>

        <div className='flex flex-row h-11 w-full bg-transparent border-t'>
          <button
            className='flex flex-1 bg-white text-gray-600  h-full outline-none border-r'
            onClick={() => {
              setOpen(false);
            }}
          >
            <span className='m-auto text-base antialiased'>取消</span>
          </button>
          <button
            className='flex flex-1 bg-white text-red-500  h-full  outline-none'
            onClick={addConfirm}
          >
            <span className='m-auto text-base font-semibold antialiased'>
              確認
            </span>
          </button>
        </div>
      </BottomSheet>
      <Modal
        isOpen={showModal}
        setIsOpen={handleToggleDelModal}
        content={'是否要清空購物車回上頁？'}
        onConfirm={onDelConfirm}
      />
    </>
  );
};

export default Products;
