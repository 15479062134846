import * as React from "react";
function SvgNext(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.89 7.445L5.222 1.519c-.296-.259-.778-.259-1.037.074-.26.297-.26.778.074 1.037L10.297 8 4.26 13.37a.751.751 0 00-.074 1.037c.148.149.333.26.556.26.185 0 .333-.074.481-.185l6.666-5.926c.148-.148.26-.333.26-.556a.666.666 0 00-.26-.555"
        fill="#DDD"
        fillRule="evenodd"
      />
    </svg>
  );
}
export default SvgNext;
