import React, { useCallback, useEffect, useState } from 'react';

import clsx from 'clsx';
import { NoData } from 'components';
import { LineHeader } from 'components/header/lineHeader';
import dayjs from 'dayjs';
import { OrderStatusMap } from 'definitions/order';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/reducers';
import { useGetOrdersQuery } from 'redux/services/common';

const Orders: React.FC = () => {
  const navigate = useNavigate();
  const [listData, setListData] = useState<any[]>([]);

  const currentRouteConfig = useSelector(
    (state: RootState) => state.rootReducer.route.currentRouteConfig
  );

  const [qureyParams, setQueryParams] = useState({
    _start: 0,
    _end: 10,
    pageSize: 10,
  });

  const {
    data: personalOrders,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetOrdersQuery(qureyParams);

  const handleBottomScroll = useCallback(() => {
    if (!isFetching && personalOrders?.pagination?.total > listData?.length) {
      setQueryParams((prev) => ({
        ...prev,
        _start: prev._end,
        _end: prev._end + prev.pageSize,
      }));
    }
  }, [isFetching, personalOrders, listData]);

  const scrollRef = useBottomScrollListener(handleBottomScroll);

  useEffect(() => {
    if (isSuccess) {
      if (listData?.length) {
        setListData((prev) => [...prev, ...personalOrders?.data]);
      } else {
        setListData(personalOrders?.data);
      }
    }
  }, [personalOrders]);

  return (
    <>
      <LineHeader title={'訂單'} subtitle={'訂單列表一覽'} />
      {listData?.length ? (
        <div
          className={clsx('list-wrapper', {
            'pb-4': !currentRouteConfig?.showBottomNav,
          })}
          ref={scrollRef as React.RefObject<HTMLDivElement>}
          style={{ backgroundColor: '#f7f7f7' }}
        >
          {listData?.map((item, index) => {
            const mapData = OrderStatusMap.get(item?.attributes?.status);

            return (
              <div
                key={index}
                className='p-2 border rounded-lg br-4 bg-white mb-2 shadow'
                onClick={() => {
                  navigate(`/order/${item?.id}`, {
                    state: item,
                  });
                }}
              >
                <div className='flex flex-row mb-2'>
                  <div className='flex flex-1 flex-col leading-none'>
                    <h1
                      className='text-lg font-semibold '
                      style={{ fontFamily: 'sans-serif' }}
                    >
                      {item?.orderType?.name ?? ''}
                    </h1>
                    <span className='text-xs text-gray-400'>
                      {item?.created_at ?? ''}
                    </span>
                  </div>
                  <div className='flex  basis-auto items-center justify-center'>
                    <div
                      className='px-4 py-1 rounded-xl shadow'
                      style={{ backgroundColor: mapData?.backgroundColor }}
                    >
                      <span
                        className='flex text-xs font-semibold'
                        style={{ color: mapData?.textColor }}
                      >
                        {mapData?.text}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='flex flex-row'>
                  <div className='flex flex-1 justify-center flex-col'>
                    <span className='text-sm'>{`${
                      item?.attributes?.detail?.length ?? 0
                    } 項商品`}</span>
                    <span className='text-sm text-gray-400'>
                      {dayjs(item?.attributes?.updatedAt).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )}
                    </span>
                  </div>
                  <div className='flex basis-auto items-center justify-center'>
                    <span className='text-2xl font-semibold'>{`$ ${(
                      (item?.attributes?.amount * 1000) /
                      1000
                    ).toLocaleString()}`}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className='flex h-full'>
          <NoData />
        </div>
      )}
    </>
  );
};

export default Orders;
