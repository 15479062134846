import React from 'react';

import { Container } from 'components';

import { useLazyLoginQuery } from 'redux/services/common';

const Login: React.FC = () => {
  const [login, { data: loginRes, isSuccess, isLoading, isFetching, error }] =
    useLazyLoginQuery();

  return (
    <Container>
      <div className='login-bg'></div>
      <section>
        <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
          <a
            href='#'
            className='flex items-center mb-6 text-2xl font-semibold text-gray-900 '
          >
            <img
              className='w-8 h-8 mr-2'
              src='https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg'
              alt='logo'
            />
            Line商店訂購系統
          </a>
          <div className='w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 '>
            <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
              <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>
                登入您的帳號
              </h1>
              <form className='space-y-4 md:space-y-6' action='#'>
                <div>
                  <label className='block mb-2 text-base font-medium text-gray-900 '>
                    帳號
                  </label>
                  <input
                    type='text'
                    name='account'
                    id='account'
                    className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
                    placeholder=''
                  />
                </div>
                <div>
                  <label className='block mb-2 text-base font-medium text-gray-900'>
                    密碼
                  </label>
                  <input
                    type='password'
                    name='password'
                    id='password'
                    placeholder=''
                    className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 '
                  />
                </div>
                <div className='flex items-center justify-between'>
                  <div className='flex items-start'>
                    <div className='flex items-center h-5'>
                      <input
                        id='remember'
                        aria-describedby='remember'
                        type='checkbox'
                        className='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 '
                      />
                    </div>
                    <div className='ml-3 text-sm'>
                      <label className='text-gray-500 '>記住我</label>
                    </div>
                  </div>
                </div>
                <button
                  type='submit'
                  className='w-full text-white bg-bc-blue2 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center '
                  onClick={(e) => {
                    e.preventDefault();

                    login({
                      account: 'ming',
                      password: '22222',
                    })
                      .unwrap()
                      .then((res) => {
                        alert('OK');
                        console.log({ res });
                      })
                      .catch((err) => {
                        alert(JSON.stringify(err));

                        console.log({ err });
                      });
                  }}
                >
                  登入
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default Login;
